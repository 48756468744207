.rls {
  &__dd {
    &--load {
      .rock-select-list-wrapper .rock-select-item-wrapper:last-child .rock-select-item-checkbox {
        display: none;
      }
    }
  }
  &__load-view {
    width: 100%; padding: 12px; display: flex; align-items: center; justify-content: center;
  }
}