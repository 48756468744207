.pset{
  &__table-tender{
    .rock-upload-filelist {
      margin-top: 0;
    }
    .rock-table-body {
      height: calc(100vh - 310px) !important;
      max-height: calc(100vh - 310px) !important;
    }
  }
  &__table-workbench{
    .rock-upload-filelist {
      margin-top: 0;
    }
    .rock-table-body {
      height: calc(100vh - 397px) !important;
      max-height: calc(100vh - 397px) !important;
    }
  }
}