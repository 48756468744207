.ftapp-table {
  width: 100%;

  // .fixFullScreen {
  //   background: #fff;
  //   padding: 24px;
  // }

  .rock-e-table-footer-wrapper {
    margin-top: 16px;
  }

  .rock-typography>div {
    height: auto !important;
    width: auto !important;
  }

  &__header {
    margin-bottom: 12px;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      // &:not(:first-child) {
      //   margin-top: 12px;
      // }
    }

    &-keyword {
      width: 370px;
    }
  }

  &__table {

    // 表头加一下：ellipsis 
    // TODO: 这里有问题，会到fixed列的box-shandow被盖住
    .rock-table-thead .rock-table-cell .rock-table-th-wrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      display: block !important;
    }

    .rock-table-tbody .rock-table-cell {
      vertical-align: middle !important;
    }
  }

  &__query {
    flex: 1;
    padding: 0;

    .light-query-operation-btns {
      .rock-btn {

        &:nth-child(1),
        &:nth-child(3) {
          display: none;
        }
      }
    }

    &-container {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }

    &-checkbox {
      height: 28px;
      padding: 0 8px;
      margin: 0 8px 0 0;
      display: inline-flex;
      align-items: center;
      position: relative;
      background: var(--fill-1);
      border-radius: var(--border-radius-base);
      padding: 0 8px;

      &:hover {
        background-color: var(--fill-2);
      }

      &-label {
        font-size: 14px;
        line-height: 22px;
        color: #0C1E3D;
        padding-right: 8px;
      }
    }
  }
  &__full-container {
    width: 100%;
    .fixFullScreen {
      background-color: #fff;
      padding: 24px;
      .rock-table-body {
        height: calc(100vh - 230px) !important;
        max-height: calc(100vh - 230px) !important;
      }
    }
  }
}

