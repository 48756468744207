.ftev {
  z-index: 120; position: absolute; bottom: 40px; right: 0;  display: flex; justify-content: flex-end;
  &__icon { font-size: 24px; }
  &__echo {
    width: 470px; height: 624px; background: #FFF;
    margin-right: 35px;
    border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 8px;
    box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1), 0px 2px 16px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(0, 0, 0, 0.02);
    overflow: hidden;

    &-header {
      width: 100%; padding: 12px 16px; font-size: 18px; line-height: 26px; font-weight: 600;
      display: flex; align-items: center; justify-content: space-between;
    }
    .echo-iframe {
      height: calc(100% - 50px);
    }
  }

  &__btn {
    margin-right: 0;
    
      &-hide {
        position: absolute; bottom: 0; right: 0;
        width: 40px; height: 44px; border: 1px solid var(--Border---border-color-2, rgba(228, 229, 235, 1));
        cursor: pointer; border-top-left-radius: 30px; border-bottom-left-radius: 30px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
        display: flex; align-items: center; background: #fff;
        .rock-icon { margin-left: 10px; }
        // opacity: 1;
      }
    
      &-show {
        cursor: pointer; border-radius: 30px; margin-right: 35px; padding-top: 16px;
        width: 60px; height: 94px; border: 1px solid var(--Border---border-color-2, rgba(228, 229, 235, 1));
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
        display: none; flex-direction: column; align-items: center; background: #fff;
        &-text { white-space: pre-wrap; line-height: 20px; font-weight: 500; text-align: center; }
        // transition: opacity 0.5s ease;
        // opacity: 0; pointer-events: none
      }
    
    &:hover {
      .ftev__btn-hide {
        display: none;
        // opacity: 0;
      }
      .ftev__btn-show {
        display: flex;
        opacity: 1;
        // opacity: 1;
        // pointer-events: auto;
      }
    }
  }
}