.peihome {
  &__sh-alert { margin-top: 12px; }

  &__tabs {
    margin-top: -18px;
    .rock-tabs-nav { 
      background: #fff; padding: 0 150px; 
      margin-bottom: 0 !important;
      position: sticky; top: 180px; z-index: 10;
    }
    .rock-tabs-extra-content { display: none; }

    &--changed .rock-tabs-nav { top: 228px; }
  }

  &__main {}
}