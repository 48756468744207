.cinvoice {
  &__status {
    padding: 0 8px;
    line-height: 24px;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
  
  
    &-invoice-check {
      background-color: #f0f1f2;
      color: #394766;
      &--2{
        background-color: var(--status-success-5);
        color: var(--status-success-1);
      }
      &--3 {
        background-color: var(--status-danger-5);
        color:var(--status-danger-1);
      }
    }
  
    &-pay{
      &--01 {
        background-color: #f0f1f2;
        color: #394766
      }
  
      &--02 {
        background-color: #e0ffec;
        color: #21a364;
      }
    }
  }

  &__attachment {
    width: 230px;
    .rock-upload-trigger-wrapper {
      display: none;
    }
    .rock-upload-fileitem-name { padding-right: 36px; }
  }
  
  &__invoice-fdb {
    .cdv__db-body {
      margin: -10px -10px;
      > div { margin: 10px; }
    }
    .cdv__fm-item--w667, .cdv__di--w667 { width: 263px; }
  }

  &__company-select {
    width: 100%;
    .rock-tag { display: inline-block; margin-top: 4px; }
  }

  &__st-l {
    width: 216px !important; height: calc(100vh - 77px); 
    border-right: 1px solid var(--Line---line-deep, #DFE0E5);
  }

  &__il {
    padding: 16px;
    &-item {
      width: 100%; display: flex; justify-content: center; flex-direction: column;
      &-atta {
        position: relative; width: calc(100% - 8px); height: 106px;
        display: flex; justify-content: center; align-items: center;
        border-radius: 6px;
        background: var(--Primary---primary-6, #C2E0FF);
        >img {
          width: 160px; height: 90px; object-fit: cover;
          border: 2px solid var(--Primary---primary-1, #004FD6)
        }
        &-show {
          width: 24px; height: 24px; position: absolute; bottom: 12px; right: 12px;
          display: flex; align-items: center; justify-content: center; border-radius: 6px;
          cursor: pointer; background: var(--Opacity-Black-50, #00000080);
          .rock-icon { color: #FFF; }
        }
        &::after {
          content: ""; position: absolute; top: 50%; right: -8px;
          transform: translateY(-50%); border-width: 8px 0 8px 8px;
          width: 0; height: 0; border-style: solid;
          border-color: transparent transparent transparent #C2E0FF;
        }
      }
      &-info {
        display: flex; justify-content: center; margin-top: 4px;
        &-name {
          flex: 1; font-size: 14px; line-height: 22px; font-weight: 600; color: #004FD6;
          white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-left: 8px;
        }
      }
    }
  }

  
  &__iiv {
    display: flex; overflow: hidden;
    border-top: 1px solid var(--Line---line-deep, #DFE0E5);
    &-r { 
      position: relative; flex: 1; 
      &-s {
        height: calc(100vh - 131px) !important;
        padding: 16px;
        &--full {
          height: calc(100vh - 48px) !important;
        }
      }
    }
  }

  &__idv { margin-bottom: 24px; }

  &__rd-item-header{
    &-view {
      margin-right: 20px; display: flex; align-items: center; justify-content: center; flex-wrap: wrap;
      margin-bottom: 8px;  font-size: 12px;
    }
    &-label { font-size: 12px; line-height: 22px; color: #68768F; margin-right: 4px; }
  }

}
