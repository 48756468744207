.shipping-order {
  font-family: SimSun, sans-serif;
  max-width: 300mm;
  margin-bottom: 20px;
  background-color: #fff;
  display: none;
  // height: fit-content;
  .page-header {
    text-align: center;
    border-bottom: solid 4px #000;
    color: #000;
    padding-bottom: 4px;
  
    .page-header-content{
      display: flex;
      justify-content: space-between;  
    }
    
    .page-header-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    
    .page-header-data {
      font-size: 10px;
      color: #68768F;
    }
  
    .page-header-info{
      display: flex;
      div{
        font-size: 10px;
        span{
          font-weight: 600;
        }
      }
      div + div {
        margin-left: 40px;
      }
    }
  }
  .signatures {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: 600;
    p { width: 183px;}
  }
  
  .qr-list {
    display: flex;
    justify-content: end;
    
    .qr-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 10px;
      color: #000;
    
      div {
        height: 16px;
      }
      
      .qr-code {
        margin-bottom: 4px;
        padding: 6px;
        border: 1px solid #E4E5EB;
        border-radius: 4px;
      }
    }
  
    .qr-item + .qr-item {
      margin-left: 16px;
    }
  }
  
  .order-items {
    // margin-top: 154px; //首页让开固定头和固定信息
    width: 100%;
    border-collapse: collapse;
    
    th {
      border: 1px solid #000;
      padding: 8px;
      text-align: left;
      font-size: 10px;
      font-weight: 600;
      background-color: #E4E5EB;
      //打印时表头颜色强制渲染
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  
    td {
      border: 1px solid #000;
      padding: 8px;
      text-align: left;
      font-size: 10px;
    }
  }
  
  .fill-block{
    height: 70px;
  }

  .fill-block-qr{
    height: 20px;
  }

  .order-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  
    .order-info-content {
      display: flex;
      justify-content: space-between;
    }
    
    .order-info-title{
      font-weight: 600;
      margin-bottom: 8px;
    }
    
    .left-info, .right-info {
      padding: 10px;
      border: 1px solid #000;
      border-radius: 4px;
      font-size: 10px;
      width: 49.5%;
      line-height: 16px;
      .info-item {
        display: flex;
        width: 100%;
        .label{
          width: 60px;
        }
        .address{
          height: 40px;
          flex: 1;
        }
      }
    }
  
    .table-title {
      margin-top: 8px;
      font-size: 10px;
      color: #000;
    }
  }
}

@media print {
  @page {
    margin: 10mm;
    @top-center {
      content: '';
    }

    @bottom-center {
      font-size: 8px;
      color: #000;
      content: counter(page) " / " counter(pages);
      bottom: 10px;
    }
  }

  body {
    // margin: 0;
    padding: 0;
  }


  .order-info{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .shipping-order {
    max-width: none;
    padding: 0;
    display: block;
  }
  
  .signatures {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: 600;
    font-size: 10px;
    p { width: 183px;}
  }

  .page-header {
    top: 0;
    left: 0;
    right: 0;
  }

  .order-items {
    page-break-inside: auto;
  }
  
  .order-items tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .qr {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}