.psei {
    &__table{
      .rock-upload-filelist {
        margin-top: 0;
      }
        .rock-table-body {
            height: calc(100vh - 310px) !important;
            max-height: calc(100vh - 310px) !important;
          }
    }
    &__attachment {
      width: 230px;
      .rock-upload-trigger-wrapper {
        display: none;
      }
        .rock-upload-fileitem-name { padding-right: 36px; }
      }
}
