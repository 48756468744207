.psetpwd {
  width: 520px; height: 670px; padding: 32px 80px;
  background: #FFFFFF; margin: 20px auto; border-radius: 16px;
  position: relative; display: flex; flex-direction: column;
  &__title {
    font-size: 24px; line-height: 32px; font-weight: 600; color: #0C1E3D;
    margin-bottom: 32px; text-align: center;
  }
  
  &__submit { height: 40px; }
  &__back { margin-top: 32px; }
  &__form {
    .rock-form-item {
      .rock-input-wrapper { width: 100%; }
    }
  }
}
