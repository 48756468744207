.lorder {
  // height: calc(100vh - 56px);
  display: flex;
  
  &__sider {
    width: 220px !important;
    max-height: calc(100vh - 56px);
    z-index: 1;
    flex-shrink: 0;
    background: var(--background-bg-1, #F0F1F2);
    // border-right: 1px solid var(--line-line-deep, #DFE0E5);
  }

  &__c { position: relative; flex: 1;
    &-with-sider { width: calc(100vw - 220px) }
  }

  &__menu {
    padding: 20px 12px !important;

    &-link {
      overflow: hidden;
      color: #394766;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;

      /* 14px/Regular */
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }

    .rock-menu-submenu-title {
      padding: 9px 12px !important;
    }

    .rock-menu {

      &-item {
        padding: 9px 12px !important;
        margin-top: 4px !important;
        margin-bottom: 0 !important;

        &-selected {
          background-color: transparent !important;
          a {
            font-weight: 600;
          }


          .ccv__menu-link {
            color: #004FD6;
            font-weight: 600 !important;
          }
        }

        // &-active {
        //   background-color: transparent !important;
        // }

        &-level-3 {
          padding: 9px 40px 12px !important;
        }

        &:hover {
          background-color: #C1C6D1;
        }
      }
    }
  }
}
