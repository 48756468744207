.pquotes {
  &__fc { margin-top: 0 !important; .ftdv__card-body { margin-top: 0 !important; } }
  .rock-upload-tip { color: #68768F; }
  &__fmi-nowrap { white-space: nowrap; }
  .ftdv__card-title { width: 100%; }

  &__ltv {
    display: flex; align-items: flex-start; justify-content: space-between;
    &-award {
      color: #21A364; background: #E0FFEC; padding: 1px 6px;
      border-radius: 4px; font-size: 12px; line-height: 18px;
      display: flex; align-items: center;

      .rock-icon { margin-right: 2px; }
    }
  }
  .line-chars__history { margin-top: 16px; }

  .cpv__header-action { display: flex; align-items: center; }

  &__header-tm {
    display: inline-flex; align-items: flex-start;
    line-height: 22px; color: var(--text-color-3);
  }
  &__bls {
    &--discarded {
      .ftdv__di-value { color: #68768F !important; }
    }
    &-title {
      display: flex; align-items: center;
      .rock-tag { margin-left: 8px; line-height: 18px; font-size: 12px; font-weight: 400; }
    }
  }
}
