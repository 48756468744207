.cpv {
  &__page { width: 100%; }

  &__loading {
    width: 100%; text-align: center; margin: 40px 0;
  }
  
  &__header {
    width: 100%; min-height: 68px;
    padding: 16px 24px; background: #FFFFFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    border-top: 1px solid #DFE0E5;
    position: relative; z-index: 1;
    display: flex; align-items: center; justify-content: space-between;
    position: sticky; top: 56px; z-index: 10;

    &-title {
      display: flex; align-items: center;
      font-size: 20px; font-weight: 700;
      line-height: 24px; color: #0C1E3D;
    }

    &-action {
      >* { margin-left: 12px; }
      .rock-btn { margin-left: 12px; }
    }
  }

  &__content { overflow-y: auto; }

  &__list {
    height: 100%; width: 100%; padding: 16px 24px;
    &-content {
      width: 100%; height: 100%; 
      padding: 24px 24px 16px 24px;
      border-radius: 8px; background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.05);
    }
  }

  &__main {
    width: 100%; padding: 16px 0 0; overflow: auto;
    position: relative; display: flex; flex-direction: column;
    &-content {
      flex: 1; width: 960px; margin: 0 auto 20px; min-height: calc(100vh - 211px);
    }
    &--w1220 { width: 100%; .ftdv__container { width: 1220px; } .cpv__main-content { width: 1220px; } }
    &--w1280 { width: 100%; .ftdv__container { width: 1280px; } .cpv__main-content { width: 1280px; } }
  }

  &__print-btn { width: 112px; }

  &__footer {
    border-top: 1px solid #DFE0E5; padding: 16px 24px;
    display: flex; justify-content: space-between; align-items: center;
    position: sticky; bottom: 0; z-index: 10; background: #F0F1F2;

    &-l { display: flex; align-items: center; }
    &-log { object-fit: cover;
      &-d { width: 80px; height: 18px; }
      &-p { width: 130px; height: 16px; }
    }
    &-divider { margin: 0 16px !important; border-left-color: #969FB2 !important; }
    &-r { font-size: 12px; line-height: 18px; color: #969FB2; }
  }
}
