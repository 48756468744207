.pregi {
  width: 640px; min-height: 800px; padding: 32px 80px; 
  background: #FFFFFF; margin: 0 auto; border-radius: 16px;
  position: relative; display: flex; flex-direction: column;

  &__content { flex: 1;
    .rock-empty,
    .rock-spin {
      position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
    }
  }

  &__merge-view {
    display: flex; align-items: flex-start;
    .rock-form-item { flex: 1; }
    .rock-form-item + .rock-form-item { margin-left: 20px }
  }

  &__title {
    font-size: 24px; line-height: 32px; font-weight: 600; color: #0C1E3D;
    margin-bottom: 32px; text-align: center;
  }
  
  &__submit { width: 100%; height: 40px; }
  &__back { margin-top: 32px; }
  &__form {
    .rock-form-item {
      margin-bottom: 16px;
      .rock-input-wrapper { width: 100%; }
      .rock-form-item-label{
        margin-bottom: 4px;
      }
    }
  }

  &__f {
    font-size: 14px; line-height: 22px; font-weight: 500; text-align: center;
    color: var(--text-color-2);
    margin-top: 32px; &-login { color: #004FD6; cursor: pointer; }
  }

  &__agreed { margin-bottom: 32px; color: var(--text-color-3); }

  &__protocol { color: #1C59D4; cursor: pointer; }

  &__file-upload {
    width: 100%;
    .rock-upload-trigger-wrapper {
      width: 100% !important;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .rock-upload-tip {
        margin-top: 8px;
        margin-left: 0;
        color: #68768F;
      }
    }
  }
}
