.pseid {
    &__drawer {
      .rock-drawer-title {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .rock-drawer-body {
        padding: 0; overflow: hidden;
        display: flex; flex-direction: column;
      }
      &-view{
        flex: 1;
      }
    }
    &__content{
        margin: 16px;
    }
    &__header{
        margin-bottom: 12px;
        &-title{
            margin-right: 16px;
            font-weight: 600;
            font-size: 16px;
            color: var(--text-text-color-1, #0C1E3D);
        }
    }
    &__rd {
        width: 100%;
        margin-top: 24px;
        &-header { display: flex; align-items: center; justify-content: space-between; margin-bottom: 12px; flex-wrap: wrap; }
        &-title { font-size: 16px; line-height: 24px; font-weight: 600; color: #0C1E3D; }
        &-all-amount {
          display: flex;
          &-label { font-size: 14px; line-height: 22px; color: #394766; }
        }
        &-item-header {
          width: 100%; padding:0; display: flex; align-items: center; flex-wrap: wrap; font-size: 12px;
          &-view {
            margin-right: 20px; display: flex; align-items: center; justify-content: center; margin-bottom: 8px;
          }
          &-label { font-size: 12px; line-height: 22px; color: #68768F; }
        }
        &-item-header + .rock-table { margin-top: 4px;} 
        &-line-item {
          margin: 12px 0;
        }
        &-line-item + &.rock-btn { margin-top: 12px; }
    }
    &__user-confirm{
      margin-top: 24px;
      &-header{
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color-1);
      }
      &-content{
        margin-top: 12px;
      }
      &-label{
        margin-left: 10px;
        font-size: 14px;
        color: var(--text-color-4);
      }
    }
    
}
