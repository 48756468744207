.prepwd {
    &__content{
        width: 520px;
        min-height: 470px;
        margin: 24px auto;
        padding: 40px 80px 80px;
        border-radius: 16px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
    }
    &__title{
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: var(--text-color-1);
        font-size: 24px;
        line-height: 32px;
    }
    &__form {
        margin-top: 32px;
        .rock-form-item {
            .rock-form-item-label{
                color: var(--text-color-3);
            }
          .rock-input-wrapper { width: 100%; }
        }
    }
    &__submit{
        margin-top: 8px;
        width: 100%;
    }
}