.ftcm {
  &__fm {
    .rock-form-item {
      border-bottom: none; width: 100%;
      &-label { display: none; }
      &-input { padding: 0; }
    }
    .rock-form-item + .rock-form-item { margin-top: 12px; }
  }
  &__show{
    margin-bottom: 20px;
    &-header{
      display: flex;
      align-items: center;
      &-info{
        margin-left: 8px;
        &-name{
          font-size: 14px;
          color: var(--text-color-1);
        }
        &-time{
          font-size: 12px;
          color: var(--text-color-3);
        }
      }
    }
    &-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      border-radius: 20px;
      &--supplier{
        border: 1px solid var(--border-color-2)
      }
    }
    &-content{
      margin-top: 8px;
      border-radius: var(--border-radius-lg);
      background: var(--fill-4);
      height: 350px;

      &-title{
        text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: var(--text-color-1);
        width: 100%;
        padding: 16px 16px 8px;
      }
      .rock-editor{
        background-color: unset;
        border: 0;
      }
      &--light{
        background: var(--Opacity-Brand-6, #004FD60F);
      }
    }
  }

  &__divider{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--text-color-3);
    margin-bottom: 20px;
    
    &::before,&::after{
      content: '';
      flex: 1;
      height: 1px;
      background: var(--border-color-2)
    }
    &::before{
      margin-right: 16px;
    }
    &::after{
        margin-left: 16px;
    }
  }
  &__clarify-title {
    font-weight: 600; color: #004FD6; text-decoration: underline; cursor: pointer;
    >span {
      max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    }
  }
}