.cwb-quote{
    &:not(:first-child) {
        margin-top: 20px;
    }
    &__qlih {
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-info {
            display: flex;
            align-items: center;
            &-rfxId{
                font-size: 16px;
                font-weight: 600;
                color: var(--text-color-1);
            }
            &-name{
                margin-left: 16px;
                color: var(--text-color-3);
            }
        }
    }
    &__qlidv{
        padding: 0 16px;
        &-item + &-item { border-top: 1px solid #F0F1F2; }
        &-item{
            display: flex;
            align-content: center;
            padding: 8px 0;
            width: 100%;
            &-name{
                width: 31%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
            }
            &-brand,
            &-config,
            &-number {
                width: 23%;
            }
        }
        
    }
    &__qlif {
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-count{
            color: var(--text-color-3);
            span{
                padding: 4px;
                font-weight: 600;
                color: var(--text-color-1);
            }
        }
       
    }

    &__label-danger{
        color: var(--status-danger-1);
    }
}