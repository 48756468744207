.layout {
  &__header {
    height: 56px; width: 100%; padding: 12px 20px; 
    position: sticky; top: 0; z-index: 10;
    background-color: #fff; display: flex; align-items: center; 
    &-center { flex: 1; display: flex; align-items: center; }
  }

  &__logo {
    width: 194px; height: 24px;
    background: url('@/assets/app-logo.png') no-repeat;
    background-size: 100%;
  }

  &__enterprise {
    width: 200px; margin-left: 24px;
    &-icon {
      font-size: 16px; margin-right: 8px;
    }
    &-dropdown { width: 430px !important; }
  }

  &__menu {
    height: 32px; flex: 1; margin: 0 24px;
    display: flex; align-items: center;
    border-bottom: none;

    .rock-menu-item { 
      margin: 0 6px; padding: 5px 16px; border-radius: 6px;
      &:hover { background: #E0F1FF; }
    }
    .rock-menu-item-inner { 
      padding: 0 !important; background-color: transparent !important; 
    }
    .rock-menu-item-selected {
      background: #E0F1FF; font-weight: 600;
    }

    &-tag { margin-left: 8px; }

    &-link {
      font-size: 14px; font-weight: 400;
      line-height: 22px; color: #394766 !important;
    }
    .rock-menu-item-selected &-link {
      font-weight: 600; color: #004FD6 !important;
    }
  }

  &__user {
    &-dropdown { 
      padding: 0; margin-left: 20px; 
      &:hover { background: none; }
    }
    &-menu &-avatar {
      .rock-icon-user {
        font-size: 24px !important; margin-right: 0 !important;
      }
    }
    &-divider {
      height: 1px; width: calc(100% - 12px); 
      background: #DFE0E5; padding: 0;
      pointer-events: none;
    }
    &-info {
      &:hover, &:active { background: none !important; }
    }
    &-data { margin-left: 8px; }
    &-name {
      font-size: 14px; font-weight: 500;
      line-height: 22px; color: #0C1E3D;
    }
    &-desc {
      font-size: 12px; font-weight: 400;
      line-height: 18px; color: #68768F;
    }
  }
}
