.pseic-lines{
    &__header{
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    &__content{
        &--hidden{
            height: 0;
            visibility: hidden;
        }
        
    }
    &__footer{
        margin-top: 12px;
        margin-bottom: 12px;
        .rock-btn + .rock-btn {
            margin-left: 12px;
        }
    }
    &__card{
        &-collapse-panel {
            cursor: default;
            .rock-collapse-header {
                min-height: 0 !important;
                padding: 0 !important;
                margin: 12px 0 !important;
                .rock-collapse-arrow{
                    color: var(--primary-1) !important;
                    font-size: 14px !important;
                }
            }
        }
    }
    &__card-collapse{
        &-title{
            width: 100%;
            cursor: default;
        }
    }
}