.ftpro-table {
  &__atpp {
    &-wrapper { 
      .b-eed-table-fullscreen {
        .rock-table-sticky-holder {
          top: 0 !important;
        }
      }
    }
    // .rock-table-pagination-position { margin-top: 0 !important; }
  }
  
  &__atpf {
    .b-eed-filter-group { margin-bottom: 0 !important; }
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      // &:not(:first-child) {
      //   margin-top: 12px;
      // }
    }
    
    &-keyword {
      width: 370px;
    }
    
    &-checkbox {
      height: 32px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      position: relative;
      background: var(--fill-1);
      border-radius: var(--border-radius-base);
      padding: 0 8px;

      &:hover {
        background-color: var(--fill-2);
      }

      &-label {
        font-size: 14px;
        line-height: 22px;
        color: #0C1E3D;
        padding-right: 8px;
      }
    }
  }

  &__atpp {
    .b-eed-search-wrapper { width: 370px; }
    // &-search { flex-basis: 100%; width: 370px !important; }
    // .b-eed-filter-group {
    //   .rock-space-item:first-of-type { flex-basis: 100%; width: 370px; }
    // }
  }
}