.sp-show {
  &__sh {
    display: flex; padding: 20px 24px 0; 
    position: sticky; top: 56px; z-index: 10;
    background: #fff; box-shadow: 0px 1px 2px 0px #00000026;
    // TODO: 阴影样式修订
    // position: relative; z-index: 1; // 为了box-shadow下边阴影显示
    border-top: 1px solid #DFE0E5;
    &-logo {
      height: 100px; width: 100px;
      border: 1px solid #DFE0E5;
      display: flex; justify-content: center; align-items: center;
      border-radius: var(--border-radius-xl);
      .rock-icon { color: #394766; }
    }
    &-content { margin-left: 24px; padding-bottom: 20px; flex: 1; }
    &-row { display: flex; justify-content: space-between; }
    &-brief { display: flex; align-items: center; flex: 1; }
    &-title {
      font-size: 20px; font-weight: 600; flex: 1;
      line-height: 28px; color: #1D2329; max-width: max-content;
      white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
    }
    &-coop { margin-left: 16px; }
    &-tags { margin-left: 8px !important; }
    &-action {
      .rock-btn { margin-left: 20px; }
    }
    &-info {
      width: 100%; padding: 16px; margin-top: 12px;
      border-radius: 8px; background: #F8F9FA;
      display: flex; align-items: center;
    }
    &-item {
      display: flex; align-items: center; flex: 1;
      font-size: 14px; font-weight: 400;
      line-height: 22px; text-align: left;
      &:not(:first-child) { margin-left: 12px; }
    }
    &-label { flex-shrink: 0; color: var(--text-color-3); }
    &-value { 
      color: #0C1E3D; margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__tags {
    display: flex; align-items: center; 
    flex-wrap: nowrap; margin: 0 -8px;
  }

  &__tag {
    height: 24px; padding: 3px 8px; margin: 0 8px;
    font-size: 12px; font-weight: 400; line-height: 18px; 
    display: inline-block; border-radius: 4px;

    &-1 {
        background-color: #FFEBE6;
        color: #E55140;
    }
    &-2 {
        background-color: #FFF6E5;
        color: #E08216;
    }
    &-3 {
        background-color: #E6E7FF;
        color: #4E37E5;
    }
    &-4 {
        background-color: #E0F3FF;
        color: #1C80EB;
    }
  }

  &__blockTitle {
    display: flex; align-items: center;
    .rock-icon { margin-left: 4px; color: #68768F; }
    .rock-tag { margin-left: 4px; }
  }

  &__blockTip {
    &-overlay {
      max-width: 680px !important;
    }
  }

  &__tip {
    &-row { display: flex; }
    &-no { margin-right: 2px; }
  }

  &__coop-status {
    display: inline-block;
    height: 24px; padding: 3px 8px; 
    font-size: 12px; line-height: 18px;
    border-radius: var(--border-radius-base-inside);
    &--01 { color: #E08216; background: #FFF6E5; }
    &--02 { color: #1C80EB; background: #E0F3FF; }
    &--03 { color: #1C80EB; background: #E0F3FF; }
    &--04 { color: #21A364; background: #E0FFEC; }
    &--05 { color: #21A364; background: #E0FFEC; }
    &--06, &--07 { color: #394766; background: #F0F1F2; }
  }

  &__onboard-status {
    height: 24px; padding: 3px 8px; 
    font-size: 12px; line-height: 18px;
    &:not(:first-child) { margin-left: 16px; }
    border-radius: var(--border-radius-base-inside);
    color: #1C80EB; background: #E0F3FF;
    &--03 { color: #E55140; background: #FFEBE6; }
  }
  &__oaflow-status {
    height: 24px; padding: 3px 8px; 
    font-size: 12px; line-height: 18px;
    &:not(:first-child) { margin-left: 16px; }
    border-radius: var(--border-radius-base-inside);
    color: #1C80EB; background: #E0F3FF;
    &--04 { color: #E55140; background: #FFEBE6; }
  }

  &__alert-content {
    line-height: 28px;
    &-title { font-weight: 600; }
  }

  &__diff {
    &-blocktitle { display: flex; align-items: center; }
    &-btns { 
      display: flex; align-items: center; 
      .rock-tag { margin-left: 16px; margin-right: 8px; }
    }
    &-drawer { 
      .rock-drawer-content-wrapper { width: 640px !important; }
      &--forTable {
        .rock-drawer-content-wrapper { width: 1080px !important; }
      }
    }
    &-item { margin-bottom: 24px; }
    &-title {
      font-size: 16px; font-weight: 600;
      line-height: 24px; margin-bottom: 16px;
    }
    &-content { background: #F8F9FA; padding: 26px 36px; border-radius: 8px; }
    &-row {
      display: flex;
      &:not(:first-child) { margin-top: 12px; }
    }
    &-label { flex-shrink: 0; color: #68768F; margin-right: 8px; }
    &-value {
      .rock-upload-filelist { margin-top: 0; }
    }
    &-cell {
      &--diff, &--add {
        color: #E08216;
        .rock-typography, .rock-upload-fileitem-name {
          color: #E08216;
        }
      }
      &--del { text-decoration: line-through; }
    }
  }

  &__data-item {
    &--diff {
      .cdv__di-value,
      .rock-upload-fileitem-name {
        color: #E08216 !important;
      }
    }
    .rock-upload-filelist { margin-top: 0; }
  }

  &__cate {
    &-list {
      display: flex; align-items: center; 
      flex-wrap: wrap; margin: -4px;
    }
    &-item {
      border-radius: var(--border-radius-base-inside);
      border: 1px solid var(--border-color-2, #DFE0E5);
      background: #F0F1F2; padding: 3px 8px; margin: 4px;
      &--diff, &--add { color: #E08216; }
      &--del { text-decoration: line-through; }
    }
  }

  &__attachment {
    .rock-upload-fileitem {
      max-width: 450px;
    }
    .rock-upload-fileitem-name { padding-right: 12px; }
  }

  &__bank {
    &-account {
      font-size: 14px; font-weight: 400;
      line-height: 22px; color: #0C1E3D;
    }
    &-tag {
      height: 20px; padding: 1px 4px;
      border-radius: var(--border-radius-base-inside);
      font-size: 12px; line-height: 18px;
      background: #E0F3FF; color: #1C80EB;
    }
    &-status {
      margin-right: 4px;
      &--1 { background: #E0FFEC; color: #21A364; }
      &--0 { background: #F0F1F2; color: #394766; }
    }
  }

  &__dbBodyForTable {
    margin: 0;
    .rock-upload-filelist { margin-top: 0 !important; }
    >div { margin: 0; }
  }

  &__modal {
    width: 498px;
    .cdv__db-body { margin: 0 -20px; padding-bottom: 12px; }
    
    &-bank, &-successCases { width: 746px; }
  }
  &__st {
    .rock-table-footer { background: #FFF; } 
  }
}
