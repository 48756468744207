.peiarchives {
    height: calc(100vh - 242px);
  
    .cpv__main-content,
    .cdv__dp {
      min-height: calc(100vh - 332px);
    }
  
    &__tabs {
      margin-bottom: 20px;
    }
  
    &__tl {
      width: 100%;
      padding-left: 0;
      min-height: calc(100vh-440px);
    }
  
    &__empty {
      margin-top: 110px;
      margin-bottom: 110px;
  
      &-text {
        &-search {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 22px;
          color: #68768F;
  
          .rock-btn {
            margin-left: 4px;
          }
        }
      }
    }
  
    &__common {
      width: 100%;
      .light-query {
        padding: 0;
        margin-bottom: 20px;
  
        .light-query-operation-btns {
          display: none;
        }
  
        &:nth-child(2) {
          margin-top: 0;
  
          .rock-form {
            margin-top: 0;
          }
        }
      }
    }
  
    &__li {
      width: 100%;
      position: relative;
  
      &-title {
        display: flex;
        align-items: center;
  
        .ct {
          margin-left: 16px;
        }
      }
  
      .rock-timeline-item__tail {
        width: 1px;
        background: #004FD6;
      }
      .rock-timeline-item__content {
        max-width: 1196px;
        &__content {
          width: 100%;
        }
      }
  
      &-c {
        position: absolute;
        top: 0;
        right: 0;
      }
  
      .rock-timeline-item__head__dot--pending {
        border-color: #004FD6;
      }
  
      &-cd {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #004FD6;
      }
  
      &-l {
        width: 100%;
        display: flex;
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
  
        &-text {
          color: #68768F;
          margin-right: 8px;
          flex-shrink: 0;
        }
  
        &-val {
          color: #0C1E3D;
          margin-right: 8px;
          word-break: break-word;
        }
  
        &-remark {
          .psdar__li-l-text {
            width: fit-content;
          }
          .psdar__li-l-val {
            flex: 1;
          }
        }
      }
    }
  }
  