.line-chars{
  &__tool {
      width: 200px;
      &-supplier {
        display: block; align-items: flex-start;
        &-color {
          width: 10px; height: 10px; display: inline-block;
          border-radius: 50%; margin-right: 8px;
        }
      }
  }
  &__card-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 12px;
      background-color: #FFF;
      margin-bottom: 20px;
      padding: 12px 20px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05);

      &-heaader{
        display: flex;
        margin-bottom: 12px;
        justify-content: space-between;

        &-title{
          font-size: 16px;
          font-weight: 600;
          color: var(--text-color-1);
        }
      }
      
      &-content{
          flex: 1;
      }
      &-item + &-item {
        margin-bottom: 8px;
      }
      &-item{
          display: flex;
          &-lable{
              flex: none;
              width: 96px;
              margin-right: 20px;
              font-size: 14px;
              color: var(--text-color-3);
          }
          &-value{
              width: 264px;
              font-size: 14px;
              font-weight: 400;
              color: var(--text-color-1);
          }
      }
  }
  &__history{
      height: 360px;
      &-log{
          padding-left: 0;
          &-content{
              font-size: 14px;
              color: var(--text-color-3);
          }
          .rock-timeline-item__head{
              margin-left: -10px;
          }
          .rock-timeline-item__tail {
              left: 5px;
              width: 1px;
          }
          .rock-timeline-item__content{
              padding-bottom: 20px
          }
          .rock-timeline-item__content__title{
              font-size: 14px;
              font-weight: 600;
          }
      }
      &-modal{
        width: 740px;
        .rock-modal-body { max-height: 500px}
      }
      &-table{
        margin-bottom: 20px;
        .rock-table-body {
          height: 428px !important;
          max-height: 428px !important;
        }
      }
  }
  &__charts-search{
    width: 100%;
  }
  &__question{
    &-tabs {
      margin-bottom: 12px;
      .rock-tabs-extra-content {
        display: none;
      }
      .rock-tabs-nav {
        margin-bottom: 0 !important;
      }

      .rock-tabs-tab {
        padding: 4px !important;
        margin: 0 20px 0 0 !important;
      }
    }
    
    &-btn{
        width: 100%;
    }
    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      margin-bottom: 8px;
      width: 100%;
    }
    &-item{
      width: 100%;
      display: flex;
      cursor: pointer;
      margin-bottom: 8px;
      &-tag {
        margin-right: 8px;
        color: var(--text-color-2);
        background-color: var(--fill-1);
        
        &--1{
          color: var(--status-success-1);
          background-color: var(--status-success-5);
        }
      }
      &-title{
        flex: 1;
        color: var(--primary-1);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__attachment{
    max-width: 244px;
    .rock-upload-filelist {
      margin-top: 0;
    }
  }
}