.ftdvc-time-down{
  &__normal{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--text-color-3);
    &-item{
      display: flex;
      align-items: center;
      margin-left: 6px;
      &-time{
        width: 30px;
        height: 36px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 700;
        color: var(--status-danger-1);
        background-color: var(--status-danger-8);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
      }
    }
  }

  &__light{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text-color-1);
    &-item{
      display: flex;
      align-items: center;
      margin-left: 6px;
      &-time{
        width: 30px;
        height: 36px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 700;
        color: var(--white-1);
        background-color: var(--status-danger-1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
      }
    }
  }
  &__bidding{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    background: linear-gradient(180deg, #D23327 34.38%, #B91E12 100%);
    font-size: 12px;
    color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    &-title{
      display: flex;
      align-items: center;
      &-line{
        width: 42px;
        border-top: solid 0.5px var(--border-color-2);
      }
      &-text{
        padding: 0 10px;
      }
    }
    &-time{
      display: flex;
      align-items: center;
      margin-top: 12px;
      height: 44px;
      span{
        padding: 0 12px;
        font-size: 36px;
        font-weight: 700;
      }
      &-second{
        // min-width: 120px; 
      }
    }
  }
}