.csigin {
  &__vc {
    width: 100%; display: flex; align-items: center;
    .rock-btn { width: 102px; margin-left: 16px; }
    .rock-input-wrapper { flex: 1; }
    &-s {
      .rock-btn { width: 117px; }
    }
  }

  &__header {
    height: 64px; width: 100%; padding: 14px 20px; 
    position: relative; z-index: 10;
    background-color: transparent; display: flex; align-items: center; 
    &-extra { display: flex; align-items: center; }
  }

  &__logo {
    width: 196px; height: 32px; margin-right: 8px;
    background: url('@/assets/app-logo.png') no-repeat;
    background-size: 100%;
  }

  &__container {
    width: 100%; height: 100%;
    &-content {
      width: 100%; height: calc(100% - 64px); padding: 20px 0; overflow-y: auto;
    }
  }
}
