.pos {
    &__creat-modal{
        .rock-modal-content{
            .rock-modal-header{
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }
    }
    &__user-name{
        font-weight: 600;
    }
    &__goodsName{
        font-weight: 600;
        font-size: 16px;
    }
    &__orderQuantity{
        font-size: 18px;
        font-weight: 700;
        color: var(--status-warning-1);
    }
}