.co {
  &__copy { display: flex; align-items: center; color: #68768F; }
  &__dlgi {
    width: 100%; display: flex; flex-direction: column; align-items: flex-start;
    &-name { color: #0C1E3D; max-width: 260px;
      overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    }
    &-item { font-size: 12px; line-height: 18px; color: #68768F; margin-top: 2px; }
  }

  &__ol-header { display: flex; align-items: center; >div + div { margin-left: 8px; } }

  &__oldv {
    display: flex; align-items: center;
    &-label { color: #68768F; }
    &-value { color: #E08216; font-size: 16px; line-height: 24px; font-weight: 600; }
  }

  &__olatv {
    width: 100%;
    &-title { 
      font-size: 16px; line-height: 24px; font-weight: 500; color: #0C1E3D; margin-bottom: 8px;
      max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    }
    &-info {
      width: 100%; display: flex; align-items: center; justify-content: space-between;
      &-label { color: #68768F; }
      &-value { font-weight: 700; color: #0C1E3D; }
    }
  }
  &__qsfv {
    display: flex; align-items: center;
    &-label { color: #68768F; margin-right: 12px; }
    &-value { font-weight: 500; color: #0C1E3D; }
  }
  &__qsv {
    width: 100%; display: flex; align-items: center; justify-content: space-between;
    padding: 12px 0 12px 16px; border-radius: 8px; overflow: hidden; margin-bottom: 12px;
    background: linear-gradient(90deg, #FFE8E8 0%, #FFFFFF 91%);

    &-1 { background: linear-gradient(90deg, #FFF5E8 0%, #FFFFFF 91%); }
    
    &-2 { background: linear-gradient(90deg, #E0F1FF 0%, #FFFFFF 91%); }
    
    &-3 { background: linear-gradient(90deg, #D2FFDF 0%, #FFFFFF 91%); }
    
    &-4 { background: linear-gradient(90deg, #FFE8E8 0%, #FFFFFF 91%); }

    &-l {
      display: flex; flex-direction: column; flex: 1;
      &-status { font-size: 18px; line-height: 26px; font-weight: 600; margin-bottom: 4px; }
      &-field { display: flex; align-items: center;flex-wrap: wrap; }
    }

    .cq__qsfv:not(:last-child) { margin-right: 20px; }
  }
}
