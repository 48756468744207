.pseto{
    &__main{
      &-icon{
        .rock-icon{
          margin-right: 4px;
        }
      }
    }
    &__header{
        margin: 8px 0;
        &-title{
          line-height: 36px;
          font-size: 28px;
          font-weight: 600;
          color: var(--text-color-1);
        }
        &-range{
          font-size: 12px;
          margin: 12px 0;
          color: var(--text-color-3);
          // div + div {
          //     margin-top: 2px;
          // }
        }
        &-end{
          justify-content: center;
          margin: 12px 0;
        }
    }
    &__content{
        text-align: start;
        width: 640px;
        font-size: 14px;
        color: var(--text-color-2);
        &-rfxId{
          color: var(--text-color-1);
          font-weight: bold;
          margin-bottom: 16px;
        }
        p + p {
          margin-bottom: 16px;
        }
        &-complaint{
          font-size: 12px;
          color: var(--text-color-3);
          margin-bottom: 20px;
          .rock-link {
            margin-left: 4px;
          }
        }
    }
    &__footer{
        display: flex;
        flex-direction: column;
        margin: 20px;
        .rock-btn{
            width: 300px;
            margin-top: 12px;
        }
    }
    &__upload{
      margin-top: 12px;
      &-t{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--text-color-3);
        &-l{
          span {
            color: #e55140;
            padding-right: 4px;
          }
        }
      }
      &-btn {
        .rock-upload-trigger-wrapper{
          .rock-btn{
            width: 98px;
            height: 28px;
            font-size: 14px;
          }
        }
        &--hidden{
          .rock-upload-trigger-wrapper{
            display: none; 
          }
        }
      }
    }
    &__empty{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 376px);
      &-text {
        font-size: 16px;
        color: var(--text-color-1);
        font-weight: 600;
      }
      .rock-empty-image{
        width: 130px;
        height: 100px;
      }
    }

    &__tool {
      width: 100%; min-height: 60px;
      padding: 12px 24px;
      background: var(--fill-2);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
      border-top: 1px solid #DFE0E5;
      position: relative; z-index: 1;
      display: flex; align-items: center; justify-content: space-between;
      position: sticky; top: 56px; z-index: 10;
      color: #0C1E3D;
  
      &-title {
        display: flex; align-items: center;
        font-size: 20px; font-weight: 700;
        line-height: 24px;
        .rock-icon{
          margin-right: 8px;
        }
      }

      &--2 {
          color: var(--primary-1);
          background: var(--primary-5);
      }

      &--3 {
          color: var(--status-success-1);
          background: var(--status-success-8);
      }
      &--4 {
          color: var(--status-danger-1);
          background: var(--status-danger-8);
      }
  }
}