.psc {
  &__main { overflow-y: auto; }
  &__table { 
    width: 928px;
    .rock-form-item{
      margin-bottom: 0;
    }
    .rock-form-item-label { display: none; }
    .rock-form-item-input { padding: 0;}
    .rock-upload-fileitem {
      max-width: 250px;
    }
  }
  &__title-required{
    &::before {
      content: '*';
      display: inline-block;
      margin-right: 4px;
      color: var(--status-danger-1);
      font-size: var(--font-size-base);
      line-height: 1;
    }
  }
  .rock-upload-tip { color: #68768F; }
}
 