.cdv {
  &__dp {
    width: 100%; padding: 24px; margin-bottom: 20px; 
    border-radius: 8px; background: #fff;
    box-shadow: 0px 1px 2px 0px #00000026, 0px 0px 5px 0px #0000000D;
  }

  &__db {
    &:not(:last-child) { margin-bottom: 20px; }

    &-header {
      display: flex; align-items: center;
      justify-content: space-between; margin-bottom: 20px;
    }

    &-sign {
      width: 4px; height: 16px; margin-right: 8px;
      border-radius: 2px; background: #004FD6;
    }

    &-title {
      flex: 1; display: flex; align-items: center;
      font-size: 18px; font-weight: 600;
      line-height: 26px; color: #0C1E3D;
    }

    &-extra { display: flex; }

    &-body {
      display: flex; flex-wrap: wrap;
      margin: -10px -20px;
      >div { margin: 10px 20px; }
    }
  }

  &__di {
    width: 384px; font-size: 14px; font-weight: 400;

    &--w100 { width: 100%; }

    &--w667 { width: 384px * 2 + 40px; }

    &--w500 { width: calc(50% - 40px); }

    &-label { color: #68768F; }

    &-value {
      color: #0C1E3D; margin-top: 4px;
      word-break: break-word;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }

  &__divider { width: 100% !important; }

  &__status {
    display: inline-block; padding: 0 8px; line-height: 24px;
    border-radius: 4px; font-size: 12px; font-weight: 400;
    &-quotation {
      &--1,
      &--8,
      &--9
      {
        background-color: var(--status-warning-5);
        color: var(--status-warning-1);
      }

      &--2{
        background-color: var(--primary-5);
        color: var(--primary-1);
      }


      &--3 {
        background-color: var(--status-success-5);
        color: var(--status-success-1);
      }
      &--4,
      &--7,
      &--11 {
        background-color: var(--status-danger-5);
        color: var(--status-danger-1);
      }
      &--5,
      &--6 {
        background-color: #f0f1f2;
        color: #394766
      }
    }
  }

  &__money {
    display: inline-flex;

    &-row { display: flex; align-items: center; }

    &-value {
      display: flex; align-items: center;
      font-size: 16px; white-space: nowrap;

      &--highlight { color: #E08216; font-weight: 600; }

      &-xsmall { font-size: 14px; }

      &-small { font-size: 16px; }

      &-default { font-size: 18px; }

      &-large { font-size: 20px; line-height: 28px; }

      &-weight {
        &-normal { font-weight: 400; }
        &-bold { font-weight: 600; }
        &-extraBold { font-weight: 700; }
      }

      &--line { text-decoration: line-through; }

      &-exceed { margin-left: 4px; }
    }

    &-currency { display: inline-block; margin-right: 4px; }

    &-extra { font-size: 14px; color: #394766; }

    &-tip {
      .rock-tag { margin-right: 4px; }
    }

    &-sign {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -8px;
        left: 4px;
        border: 4px solid transparent;
        border-bottom: 4px solid var(--fill-1);
        border-left: 4px solid var(--fill-1);
        ;
      }
    }

    &-c {
      .cdv__money-row + .cdv__money-tip { margin-top: 8px; }
    }
  }

  &__currencyMoney {
    width: 100%;
    display: flex;

    >div:last-child { flex: 1; }

    .rock-select {
      width: 94px; height: max-content; margin-right: 4px;
    }
  }

  &__upload {
    .rock-upload-tip { color: var(--text-color-3); }
  }

  &__attachment {
    width: 100%;
    margin-right: 12px;

    .rock-upload-trigger-wrapper {
      display: none;
    }
  }

  &__si-datepicker {
    width: 100%;
    display: flex;
    align-items: center;

    .rock-checkbox-wrapper {
      flex-shrink: 0;
      margin-left: 16px;
    }
  }

  &__link {
    font-weight: 600;
    border-bottom: none;

    &--underline {
      border-bottom: 1px solid var(--primary-2);
    }
  }

  &__fm {
    .rock-input-number-wrapper,
    .rock-input-wrapper { width: 100% !important; }

    .rock-form-item-input-content { height: auto !important; }

    .rock-input-textarea-wrapper {
      min-height: 100px;
      .rock-input-affix-wrapper {
        min-height: 100%;
      }
    }

    .rock-upload {
      width: 100%;
      .rock-upload-trigger-wrapper {
        width: 400px;
      }
      &.rock-upload-draggable {
        .rock-uploader {
          width: 100%;
          height: 100px;
        }
      }
    }

    &-item {
      width: 384px;

      &--w100 { width: 100%; overflow: hidden; }

      &--w667 { width: 384px * 2 + 40px; }

      &--w500 { width: calc(50% - 40px); }

      &--disableLabel {
        .rock-form-item-label { display: none; }
      }
    }
  }

  &__fm-item+&__di &__di-value {
    margin-top: 12px;
  }

  &__csdd { min-width: 100px; }

  &__copy {
    display: flex; align-items: center;
    &--light {color: #004FD6;}
    .rock-icon {
      margin-left: 8px;
      color: #68768F;
    }
  }
  
  &__lmv { font-size: 14px; line-height: 22px; }

  &__comv {
    display: flex; align-items: center;
    &-name { font-weight: 600; color: #0C1E3D; }
    &-code  {
      font-size: 12px; line-height: 18px; color: #E08216; border-radius: 4px;
      border: 1px solid #E08216; padding: 1px 6px; margin-left: 8px; white-space: nowrap;
    }
  }
}
