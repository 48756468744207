.pwb {
  width: 100%;
  padding: 16px 24px;
  background-color: rgb(223, 224, 229);
  height: calc(100vh - 56px);
  position: static;
  &__tabs {
    height: 100%;
    >.rock-tabs-nav {
      margin-bottom: 0;

      >.rock-tabs-extra-content {
        display: none;
      }

      &::before {
        display: none;
      }
    }

    .rock-tabs-content {
      height: 100%;
      color: #0C1E3D;
    }

    // 保证tab和下面内容对齐
    >.rock-tabs-nav>.rock-tabs-nav-wrap>.rock-tabs-nav-list { transform: translateX(-1px) !important; }

    >.rock-tabs-content-holder>.rock-tabs-content {
      // TODO: 和tabl-header对不起，先这样吧
      width: 100%;
      // transform: translateX(1px);
      padding: 24px;
      background: #fff;
      border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
    }

    >.rock-tabs-nav>.rock-tabs-nav-wrap>.rock-tabs-nav-list>.rock-tabs-tab {
      padding: 0;
      border-radius: 8px 8px 0 0 !important;

      &,
      &:hover {
        background-color: rgb(240, 240, 241);
      }

      &-active,
      &-active:hover {
        background-color: #fff;
      }

      &:after {
        opacity: 1 !important;
      }

      &:not(:first-child) {
        margin-left: 8px !important;
      }
    }
  }
  &__has-sub {
    margin-top: -16px;
  }

  &__tt {
    padding: 12px 24px;
    width: calc((100vw - 68px) / 3);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-row {
      display: flex;
      align-items: center;

      &:first-child {
        flex: 1;
      }
    }

    &-box {
      flex: 1;
      width: 0;
    }

    &-icon {
      height: 48px;
      width: 48px;
      margin-right: 20px;
    }

    &-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #68768F;
      text-align: left;
    }

    &-total {
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      color: #0C1E3D;
      text-align: left;
    }

    &-tag {
      height: 61px;
      padding: 0 8px;
      margin-left: 12px;
      border-radius: var(--border-radius-base);
      background: #F8F9FA;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .rock-icon {
        font-size: 16px;
        color: #68768F;
      }
    }

    &-num {
      font-size: 18px;
      font-weight: 600;
      line-height: 25.2px;
      color: #0C1E3D;
      margin-top: 4px;
    }
  }

  &__subtabs {
    .rock-tabs-ink-bar {
      display: block;
      visibility: visible;
    }
    .rock-tabs-nav-list .rock-tabs-tab:not(:first-of-type) {
      margin-left: 20px !important;
    }
  }

  &__subtt {
    .rock-tag {
      margin-left: 8px;
    }
  }
}
