@import '~@bedrock/components/reset.css';
@import '~@bedrock/components/base.css';
@import '~@bedrock-e/common/lib/style.css';

html, body, #root {
  width: 100%; height: 100%; 
  background: #F0F1F2; 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  /* 全局表单的Label颜色值 */
  .rock-form-item-label {
    color: var(--text-color-3);
  }
}