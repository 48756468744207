.cwb{
    &__lfl {  color: var(--text-color-3); margin-right: 12px; white-space: nowrap; }
    
    &__lfv {
        display: flex; align-items: center;
    
        &-v { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
    }
    
    &__li {
        width: 100%;
        border: 1px solid #DFE0E5;
        border-radius: 6px;
        overflow: hidden;
        &-header { 
            background: #F8F9FA;
            border-bottom: 1px solid #DFE0E5 }
        &-footer {
            border-top: 1px solid #DFE0E5 
        }
    }

    &__diy-table{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__content {
        flex: 1;
        &-fill{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &__pagination{
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
    &__empty-text{
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color-1);
    }

    &__table-list{
        max-height: calc(100vh - 364px);
        height: calc(100vh - 364px);
    }
}