.plogin {
  width: 100vw; height: 100vh; position: relative;
  display: flex; align-items: center;
  background: url('@/assets/login-bg-img.png') no-repeat;
  background-size: cover; background-position: left top;

  &__welcome {
    flex: 1; display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    color: #FFF; font-size: 48px; font-weight: 600;
    &-text { 
      font-size: 36px; line-height: 64px; position: relative;
      &::before, &::after {
        content: ''; display: block;
        width: 72px; height: 2px; background: #fff;
        position: absolute; top: 0; bottom: 0; margin: auto;
      }
      &::before { left: -92px; }
      &::after { right: -92px; }
    }
    &-supplier { font-size: 48px; line-height: 64px; }
  }

  &__c {
    width: 560px; height: 100%;
    background: #FFFFFF; padding: 12px 16px 20px;
    display: flex; flex-direction: column; align-items: center;

    &-h { width: 100%; text-align: end; }

    &-c {
      width: 424px; padding: 32px; margin: auto; flex: 1;
      display: flex; flex-direction: column; justify-content: center;

      &-title {
        font-size: 24px; line-height: 32px; font-weight: 600; color: #0C1E3D;
        margin-bottom: 32px;
      }
      &-submit { height: 40px; }
      &-password { width: max-content; margin: 32px auto 0; text-align: center; border-bottom: none; }
      &-form {
        .rock-form-item {
          .rock-input-wrapper { width: 100%; }
        }
      }
    }
    &-f {
      display: flex; flex-direction: column; align-items: center; justify-content: center;
      &-link {
        display: flex; align-items: center; justify-content: center;
        font-size: 14px; line-height: 22px; color: #394766; margin-bottom: 16px;
        .rock-divider { margin: 0 20px; }
        &-item { color: var(--text-color-2); cursor: pointer; border-bottom: none; font-size: 12px; &:hover { color: #1c6feb; } }
      }
      &-copyright {
        font-size: 14px; line-height: 18px; color: #969FB2;
      }
    }
  }
}
