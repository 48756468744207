.sp-form {
  &__fm {
    .rock-table-placeholder .rock-table-cell { padding: 0; }
    .rock-picker { width: 100%; }

    &-base {
      width: 592px; margin: 0 auto 20px !important;
    }
    &-dbBodyForTable {
      >div { margin: 10px 0; padding: 0 20px; }
    }
    &-operate {
      display: flex; align-items: center;
      .rock-btn {
        &:not(:first-child) { margin-left: 16px; }
      }
    }
    .rock-input-textarea-wrapper { min-height: unset; }
  }

  &__blockTag {
    margin-left: 8px;
  }

  &__command-money {
    width: 100%; display: flex;
    .rock-select { width: 100px; height: fit-content; }
    >div:last-child { flex: 1; margin-left: 8px; }
  }

  &__modal {
    width: 746px;
    .cdv__db-body { margin: 0 -20px; }
    .rock-upload .rock-upload-trigger-wrapper {
      width: 100%;
    }
  }
}
