.cwb-ship {
    &:not(:first-child) {
      margin-top: 20px;
    }
  &__slih {
    width: 100%; padding: 16px; display: flex; align-items: center;
    >div + div { margin-left: 8px; }
    &-id {
      width: 30%; display: flex; align-items: center;
      font-weight: 600; color: var(--text-color-1);
      .rock-checkbox-wrapper { margin-right: 12px; }
    }
    &-info {
      flex: 1; display: flex; flex-direction: column; align-items: flex-start;
    }
    &-time {
      width: 38%; display: flex; flex-direction: column; align-items: end;
    }
  }

  &__slidv {
    padding: 0 16px;
    &-item + &-item { border-top: 1px solid #F0F1F2; }
    &-item {
      padding: 8px 0; display: flex; align-content: center;
      >div + div { margin-left: 8px; }
      &-name {
        width: 30%; display: flex; align-items: center;
        .rock-checkbox-wrapper{
          margin-right: 12px;
        }
        &-text { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
      }
      &-brand,
      &-config {
        width: 20%;
      }
      &-count{
        width: 26%;
        display: flex;
        align-items: center;
      }
    }
  }

  &__slif {
    width: 100%; padding: 16px; display: flex; align-items: center; justify-content: flex-end;
    a + .rock-btn { margin-left: 16px; }
  }
  
  &__label-warning{
    color: var(--status-warning-1);
    display: inline-block;
  }

  &__label-danger{
    color: var(--status-danger-1);
  }
}
