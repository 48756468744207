.ftcpv {
  &__page { width: 100%; background: #F0F1F2; }

  &__loading { width: 100%; text-align: center; margin: 40px 0; }
  
  &__header {
    width: 100%; min-height: 68px; padding: 16px 24px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    position: relative; z-index: 1;
    display: flex; align-items: center;

    &-title {
      display: flex; align-items: center;
      font-size: 20px; font-weight: 700;
      line-height: 24px; color: #0C1E3D;
    }

    &-content {
      flex: 1; display: flex; 
      align-items: center; justify-content: center; 
    }

    &-action {
      >* { margin-left: 12px; }
    }
  }

  &__main {
    width: 100%;
    &--scrollable {
      height: calc(100vh - 129px); overflow: auto;
    }
  }
}