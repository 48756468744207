.pquote-bidding{
    &__main{
        display: flex;
        justify-content: space-between;
        &-left{
            width: 800px;
        }
        &-right{
            width: 400px;
        }
    }
    .cpv__header-action { display: flex; align-items: center; }
    
    &__content{
      padding: 20px;
      border-radius: 12px;
      background-color: #FFF;
      &-header{
        display: flex;
        justify-content: space-between;
        &-title{
          font-size: 18px;
          color: var(--text-color-1);
          font-weight: bold;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
        }
        &-label{
          font-size: 14px;
          color: var(--text-color-3);
        }
      }
       
    }

    &__line-tags{
        display: flex;
        width: 100%;
        &-item{
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin: 4px 0 8px;
            padding: 8px 16px;
            background: var(--status-danger-8);
            color: var(--status-danger-1);
            border-radius: 4px;
            font-size: 12px;
            align-items: center;
        }
        &-label{
            display: flex;
            align-items: center;
            .rock-icon{
                margin-right: 2px;
                font-size: 20px;
            }
        }

        &-value{
            font-size: 14px;
            font-weight: 600;
        }
        
        &-item + &-item {
            margin-left: 12px;
        }
    }

    &__header {
        width: 100%; min-height: 60px;
        padding: 12px 24px;
        background: var(--fill-2);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        border-top: 1px solid #DFE0E5;
        position: relative; z-index: 1;
        display: flex; align-items: center; justify-content: space-between;
        position: sticky; top: 56px; z-index: 10;
        color: #0C1E3D;

        &-tm {
          display: inline-flex; align-items: flex-start;
          line-height: 22px; color: var(--text-color-3);
        }
    
        &-title {
          display: flex; align-items: center;
          font-size: 20px; font-weight: 700;
          line-height: 24px;
          .rock-icon{
            margin-right: 8px;
          }
        }

        &--2 {
            color: var(--primary-1);
            background: var(--primary-5);
        }

        &--3 {
            color: var(--status-success-1);
            background: var(--status-success-8);
        }
        &--4 {
            color: var(--status-danger-1);
            background: var(--status-danger-8);
        }
    }
    &__time{
        margin-bottom: 20px;
    }

    &__ltv {
        display: flex; align-items: flex-start; justify-content: space-between;
        &-award {
          color: #21A364; background: #E0FFEC; padding: 1px 6px;
          border-radius: 4px; font-size: 12px; line-height: 18px;
          display: flex; align-items: center;
          .rock-icon { margin-right: 2px; }
        }
    }

    &__empty {
      width: 100%;
      .rock-empty-image {
        width: 120px;
        height: 100px;
      }
      &-text{
          color: var(--text-color-1);
          font-size: 14px;
      }
    }
    
    &__fc { margin-top: 0 !important; .ftdv__card-body { margin-top: 0 !important; } }

    .rock-upload-tip { color: #68768F; }

    &__fmi-nowrap { white-space: nowrap; }

    .ftdv__card-title { width: 100%; }
    .rock-calendar-picker-wrapper{
        min-width: 0;
    }
    &__bls {
      margin-top: 12px;
      &--discarded {
        .ftdv__di-value { color: #68768F !important; }
      }
      &-title {
        display: flex; align-items: center;
        .rock-tag { margin-left: 8px; line-height: 18px; font-size: 12px; font-weight: 400; }
      }
    }
}