.cq {
  &__qsfv {
    display: flex; align-items: center;
    &-label { color: #68768F; margin-right: 12px; }
    &-value { font-weight: 500; color: #0C1E3D; }
  }
  &__qsv {
    width: 100%; display: flex; align-items: center; justify-content: space-between;
    padding: 12px 0 12px 16px; border-radius: 8px; overflow: hidden; margin-bottom: 12px;
    background: linear-gradient(90deg, #FFE8E8 0%, #FFFFFF 91%);

    &-1 { background: linear-gradient(90deg, #FFF5E8 0%, #FFFFFF 91%); }
    
    &-2 { background: linear-gradient(90deg, #E0F1FF 0%, #FFFFFF 91%); }
    
    &-3 { background: linear-gradient(90deg, #D2FFDF 0%, #FFFFFF 91%); }
    
    &-4,
    &-7 { background: linear-gradient(90deg, #FFE8E8 0%, #FFFFFF 91%); }
    
    &-5,
    &-6 { background: linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 91%); }

    &-l {
      display: flex; flex-direction: column; flex: 1;
      &-status { font-size: 18px; line-height: 26px; font-weight: 600; margin-bottom: 4px; }
      &-field { display: flex; align-items: center;flex-wrap: wrap; }
    }

    .cq__qsfv:not(:last-child) { margin-right: 20px; }
  }

  &__ami {
    width: 100%; display: flex; align-items: center;

    .rock-input-number-wrapper { flex: 1; }

    &-currency { margin-right: 8px; }
  }
  

  &__latv {
    width: 100%;
    &-title { 
      font-size: 16px; line-height: 24px; font-weight: 500; color: #0C1E3D;
      max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    }
    &-info {
      width: 100%; display: flex; align-items: center; justify-content: space-between;
      flex-wrap: wrap;
      &-label { color: #68768F; }; margin-top: 8px;
      &-value { font-weight: 700; color: #0C1E3D; }
    }
  }

  &__qle {
    width: 100%; border-radius: 4px; border: 1px solid var(--Line---line-deep, #E4E5EB);
    display: flex; align-items: center; justify-content: center; padding: 20px;
    svg { width: 120px; height: 100px; }
  }
}
