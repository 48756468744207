.pdl {
  &__fm {
    .rock-input-wrapper {
      width: 280px;
    }

    .rock-select {
      width: 200px;
    }
  }

  &__table {
    .rock-table-body {
      height: calc(100vh - 318px) !important;
      max-height: calc(100vh - 318px) !important;
    }
  }

  &__action {
    .rock-icon {
      font-size: 18px;
    }

    >*:not(:first-child) {
      margin-left: 12px;
    }
  }

  &__pagination {
    margin-top: 16px;
    width: 100%;
    justify-content: flex-end;
  }
  
  &__list {
    padding: 20px 24px 20px 0;
  }
}
