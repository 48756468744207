.ftdv {
  &__container {
    width: 960px; padding-bottom: 16px; margin: 0 auto; 
    position: relative; flex: 1;
    .rock-affix {
      position: absolute; left: calc(50% - 480px - 225px);

      @media screen and (max-width: 1440px) { display: none; }

      .rock-affix-child-fixed { top: 140px !important; }
      // 这里宽度222与下面204的差值，正好是滚动条的位置
      .rock-scrollbar { width: 222px !important; }
    }
  }

  &__anchor {
    padding: 0; width: 204px !important; height: fit-content;
    background-color: transparent;
    .rock-anchor-slider, 
    .rock-anchor-list::before { display: none; }
    .rock-anchor-link {
      background-color: #fff; margin: 0; padding: 12px;
      border-radius: 6px; border: 2px solid transparent;
      transition: border-color ease-in 0.15s;
      &:not(:first-child) { margin-top: 8px; }
      &-active { border-color: #004FD6; }
    }
    .rock-anchor-link-title { 
      padding: 0; color: unset !important;
      font-weight: 400 !important;
      background-color: transparent !important; 
    }
  }

  &__card {
    background: #fff; padding: 20px; border-radius: 12px;
    box-shadow: 0px 1px 2px 0px #00000026, 0px 0px 5px 0px #0000000D;

    &+& { margin-top: 16px; }
    // &:not(:first-child) { margin-top: 16px; }

    &-header { 
      width: 100%; cursor: default; 
      display: flex; align-items: center; justify-content: space-between; 
    }
    &-title {
      font-size: 18px; font-weight: 600;
      line-height: 26px; color: #0C1E3D;
    }
    &-action {
      display: flex; align-items: center;
    }

    &-collapse-panel {
      .rock-collapse-header {
        display: block !important;
        min-height: auto !important;
        padding-right: 0 !important;
      }
      .rock-collapse-content { border-top: 0 }
    }
    &-header+&-body { margin-top: 12px; }
    .rock-collapse-content { margin-top: 12px; }
    .ftdv__dg + .ftdv__dg { margin-top: 8px; }
  }

  &__dg {
    width: 100%; border-radius: 6px;
    border: 1px solid #DFE0E5;
    display: flex; flex-wrap: wrap;
    position: relative; overflow: hidden;
    &:after {
      content: ''; display: block;
      position: absolute; bottom: 0; left: 0; right: 0;
      height: 1px; background: #fff;
    }
  }

  &__db {
    width: 100%; 
    &:not(:first-child) { margin-top: 12px; }
    &-header { display: flex; justify-content: space-between; padding-top: 4px; margin-bottom: 8px; }
    &-title {
      display: flex; align-items: center;
      font-size: 14px; font-weight: 600;
      line-height: 22px; color: #0C1E3D; 
      .rock-icon { margin-right: 6px; font-size: 16px; }
    }
    &--sublevel &-title { font-weight: 400; color: #68768F; }
    &--sublevel:first-child { margin-top: 0 !important; }
  }

  &__di {
    display: flex; width: 50%;
    border-bottom: 1px solid #F0F1F2;
    &--w100 { width: 100%; }

    &-label {
      width: 146px; padding: 13px 12px; flex-shrink: 0;
      background: #F8F9FA; color: var(--text-color-3);
      text-align: right; white-space: pre-wrap;
    }
    &-value {
      flex: 1; padding: 13px 16px;
      min-width: 0; // 在flex1的子元素会溢出
      white-space: pre-wrap; word-break: break-word;
      .rock-upload:first-child {
        .rock-upload-filelist { margin-top: 0; }
      }
    }
  }

  &__fm {
    .rock-form-item-label {
      width: 146px; padding: 13px 12px;
      background: #F8F9FA; flex-shrink: 0;
      margin-bottom: 0 !important;
      font-weight: 400; color: var(--text-color-3) ;
      > label {
        font-weight: 400; color: var(--text-color-3); 
        font-size: 14px; top: -2px !important; 
      }
    }
    .rock-form-item-input {
      padding: 8px 16px; width: 0;
      .rock-form-item-input-content {
        min-height: auto !important; width: 100%;
        .rock-select {
          display: flex; flex-direction: column;
          justify-content: center;
          .rock-select-selector{ min-height: 32px; }
        }
        .rock-input-number-wrapper{ width: 100%; }
      }
    }
    .rock-input-wrapper-inline { width: 100%; }

    .rock-radio-group { display: flex; flex-wrap: wrap; }

    &-item {
      width: 50%; margin-bottom: 0;
      border-bottom: 1px solid #F0F1F2;
      .rock-form-item-input-content { height: auto; }
      
      &--disableLabel {
        .rock-form-item-label { display: none; }
        .rock-form-item-input { padding: 0; }
      }
      &--w100 { width: 100%; }
      &--w102 {
        width: 100%;
        .rock-form-item-input-content { width: 245px; }
      }
      &--h100 .rock-form-item-input-content { height: 100%; }
    }
  }

  &__upload {
    width: 100%;
    .rock-upload-tip { color: #68768F; }
  }

  &__attachment {
    width: 100%; margin-right: 12px;
    .rock-upload-trigger-wrapper { display: none; }
  }

  &__link {
    font-weight: 600;
    border-bottom: none;

    &--underline {
      border-bottom: 1px solid var(--primary-2);
    }
  }
  
  &__comv {
    display: flex; align-items: flex-start;
    &-name { font-weight: 600; color: #0C1E3D; }
    &-code  {
      font-size: 12px; line-height: 18px; color: #E08216; border-radius:  4px; margin-top: 1px;
      border: 1px solid #E08216; padding: 0px 5px; margin-left: 8px; white-space: nowrap;
    }
  }

  &__money {
    display: inline-flex;

    &-row { display: flex; align-items: center; flex-wrap: wrap;}
    &-value {
      display: flex; align-items: center;
      font-size: 16px; white-space: nowrap;

      &--highlight { color: #E08216; font-weight: 600; }

      &-xsmall { font-size: 14px; }

      &-small { font-size: 16px; }

      &-default { font-size: 18px; }

      &-large { font-size: 20px; line-height: 28px; }

      &-weight {
        &-normal { font-weight: 400; }
        &-bold { font-weight: 600; }
        &-extraBold { font-weight: 700; }
      }

      &--line { text-decoration: line-through; }
    }

    &-currency { display: inline-block; margin-right: 4px; }

    &-tip {
      text-align: left !important; margin-top: 2px;
      .rock-tag { margin-right: 4px; }
      &-weight-extraBold { margin-top: 2px; }
    }

    &-sign {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -8px;
        left: 4px;
        border: 4px solid transparent;
        border-bottom: 4px solid var(--fill-1);
        border-left: 4px solid var(--fill-1);
        ;
      }
    }

    &-c {
      .cdv__money-row + .cdv__money-tip { margin-top: 8px; }
    }
  }

  &__copy {
    display: flex; align-items: center;
    &--light {color: #004FD6;}
    .rock-icon {
      margin-left: 8px;
      color: #68768F;
      font-size: 16px;
    }
  }
  &__cut-off{
    display: flex;
    align-items: center;
    &-time{ 
      &:not(:last-child) {
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    &--timeWarning{
      color: var(--status-danger-1);
    }
  }

  &__tag {
    height: 24px; padding: 3px 8px; margin: 0 8px;
    font-size: 12px; font-weight: 400; line-height: 18px; 
    display: inline-block; border-radius: 4px;

    &-1 {
        background-color: #FFEBE6;
        color: #E55140;
    }
    &-2 {
        background-color: #FFF6E5;
        color: #E08216;
    }
    &-3 {
        background-color: #E6E7FF;
        color: #4E37E5;
    }
    &-4 {
        background-color: #E0F3FF;
        color: #1C80EB;
    }
  }
  &__cc {
    width: 960px; margin: 0 auto; padding: 20px 40px; border-radius: 12px;
    background: linear-gradient(180deg, #D8ECFF 0%, #FFFFFF 18.1%);
    height: calc(100% - 16px);
    box-shadow: 0px 1px 2px 0px #00000026;
    box-shadow: 0px 0px 5px 0px #0000000D;
    &-header {
      display: flex; align-items: center; justify-content: space-between; color: #969FB2;
    }
    &-content {
      display: flex; flex-direction: column; align-items: center;
      text-align: center; padding-bottom: 40px;
    }
  }
  &__lmv {
    font-size: 14px;
    line-height: 22px;
  }
  &__nb-tags {
    display: flex;
    align-items: center;
    margin: 0 -4px;

    &-item {
      height: 24px;
      padding: 0 8px;
      line-height: 24px;
      white-space: nowrap;
      font-size: 12px;
      border-radius: 4px;
      margin: 0 4px;
      background-color: var(--primary-1);
      color: #fff;
    }

    &--wrap {
      flex-wrap: wrap;
      margin: -2px -4px;
    }

    &--wrap &-item {
      margin: 1px 4px;
    }
  }
}
