.pseic {
  &__drawer {
    .rock-drawer-title {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .rock-drawer-body {
      padding: 0; overflow: hidden;
      display: flex; flex-direction: column;
    }
  }
  &__action {
    width: calc(100% - 48px); padding: 24px 0; position: absolute; bottom: 0;
    display: flex; align-items: center; justify-content: space-between;
    .rock-btn + .rock-btn { margin-left: 16px; }
    &-st {
      width: 100%; position: relative; padding: 18.5px 16px;
      border-top: 1px solid var(--Line---line-deep, #DFE0E5);
    }
  }

  &__loading {
    display: flex; align-items: center; justify-content: center;
    width: 100%; height: 100%;
  }

  &__form { 
    flex: 1; overflow: hidden; >div { flex: 1; } 
    .pseic__st { display: none; } &--st { .pseic__so { display: none; } .pseic__st { display: flex; } }
  }

  &__so {
    &-content{
      &--loading{
        opacity: 0.1;
      }
    }
    &-loading{
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      &-info{
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color-1);
      }
    }
    &-label {
      .rock-form-item-label{
        color: var(--text-color-3);
      }
    }
    position: relative;
    width: 100%;
    padding: 0 24px;
    .rock-upload-draggable {
      .rock-uploader { height: 200px !important; }
    }
  }

  &__st {
    display: flex; overflow: hidden;
    border-top: 1px solid var(--Line---line-deep, #DFE0E5);
    &-header { display: flex; align-items: center; margin-bottom: 12px; }
    &-name { 
      font-size: 16px; line-height: 24px; font-weight: 600; color: #0C1E3D; margin-right: 12px; 
    }
    &-l { 
      width: 216px !important; height: calc(100vh - 77px); 
      border-right: 1px solid var(--Line---line-deep, #DFE0E5);
    }
    // 第二步布局
    &-r {
      &-c { width: 100%; padding: 16px; overflow: hidden; }
    }

    // 表单内容
    &-fc { margin-bottom: 24px; 
      .rock-form-item-label { color: #68768F; }
    }
  }

  &__rc {
  margin-top: 24px;
    &-sa {
      display: flex;
      &-title{
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color-1);
        margin-bottom: 12px;

        &-tip { 
          font-size: 14px;
          line-height: 22px;
          color: #969FB2;
          margin-left: 10px;
          font-weight: 400;
        }
      }     
    }
    &-radio + &-radio { margin-left: 12px; }
    &-radio { 
      flex: 1; padding: 16px 20px; border-radius: 8px;
      border: 1px solid var(--Border---border-color-2, #E4E5EB);
      display: flex; flex-direction: column; justify-content: center; align-items: center;
      &-text { font-size: 14px; line-height: 22px; color: #394766; margin: 8px 0 12px; }
    }
    &-only-btn { margin-top: 12px; }
  }

  &__rd {
    width: 100%;
    &-header { display: flex; align-items: center; justify-content: space-between; margin-bottom: 12px; }
    &-title { font-size: 16px; line-height: 24px; font-weight: 600; color: #0C1E3D; }
    &-all-amount {
      display: flex;
      &-label { font-size: 14px; line-height: 22px; color: #394766; }
    }
    &-item-header + .rock-table { margin-top: 4px; }
    &-item-header {
      width: 100%; padding: 0; display: flex; align-items: center; font-size: 12px; flex-wrap: wrap;
      &-view {
        margin-right: 20px; display: flex; align-items: center; justify-content: center; margin-bottom: 8px;
      }
      &-label { font-size: 12px; line-height: 22px; color: #68768F; }
      &-container{display: flex; flex-wrap: wrap;}
      &-container + .rock-table { margin-top: 4px;} 
    }
    &-line-item + &-line-item { margin: 12px 0 8px; }
    &-line-item + &.rock-btn { margin-top: 12px; }

    &-search { width: 370px; margin: 0 0 12px; }
    &-amount-input {
      display: flex; align-items: center;
      .rock-input-number-wrapper { flex: 1; width: 166px !important; }
      &-fi {
        width: 178px !important; flex: 1; margin-bottom: 0; margin-left: 12px; 
        .rock-form-item-label { display: none; }
      }
    }
  }

  &__rdm {
    &-empty { margin: 80px 0; }
    &-loading { display: flex; align-items: center; justify-content: center; width: 100%; margin: 160px auto; }
  }

  .rock-upload-draggable {
    .rock-uploader { height: 200px !important; }
  }

  &__upload {
    .rock-uploader { height: 200px !important; }
    
    .rock-upload-trigger { 
      border: 1px dashed var(--Border---border-color-2, #E4E5EB) !important; 
    }
  }
  &__user-confirm{
    margin-top: 24px;
    &-header{
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-1);
    }
    &-content{
      margin-top: 12px;
    }
    &-label{
      margin-left: 10px;
      font-size: 14px;
      color: var(--text-color-4);
    }
  }
}
