.ftcom-nwp {
  display: inline-flex;
  align-items: center;
  color: #192840;
  font-weight: normal;
  line-height: 22px;

  &__name {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // &__vip {
  //   display: inline-block;
  //   width: 32px;
  //   height: 20px;
  //   margin-left: 8px;
  //   padding: 0 6px;
  //   color: #f24718;
  //   font-size: 12px;
  //   line-height: 20px;
  //   background: #fef3ed;
  //   border-radius: 2px;
  // }

  &__mail {
    margin-left: 20px;
    color: var(--n60);
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    cursor: pointer;
  }
}
