.ftbe {
  padding: 0 !important;

  .rock-editor__toolbar { 
    justify-content: flex-start;
    border-top-left-radius: 4px; border-top-right-radius: 4px;
    border-bottom: 1px solid #E3E5EB;
    position: sticky; top: 0; z-index: 10;
    background: #fff;
  }
  .prosemirror-wrapper { width: 100%; min-height: 200px; .ProseMirror{
    padding: 0 16px 16px!important;
  } }
  // .ProseMirror { 
  //   .ticket-richtext();
  //   padding: 8px !important; 
  // }
  // .ProseMirror[placeholder]::before {
  //   margin-top: 2px; font-size: 14px;
  // }

  // input[type=file] { display: none; }
}