.pss {
  &__table { width: 918px; }
  &__quantiy{
    font-weight: 600;
  }
  &__remark{
    font-size: 12px;
    color: var(--text-color-3);
  }
  &__rtl-modal { .rock-modal-body { margin-bottom: 20px; } }
}
