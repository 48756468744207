.sp-view {
  &__sl {
    display: flex;
    align-items: center;

    .rock-icon {
      margin-left: 4px;
    }

    &-pt {
      display: flex;
      align-items: center;

      .rock-btn {
        margin-left: 24px;
      }
    }
  }

  &__biz-table {
    width: 920px;
  }
}
